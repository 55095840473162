import { useAppContext } from 'shared/useAppContext';
import { createClient } from '../prismicio.js';
import * as prismic from '@prismicio/client';

export async function getDocs({
  options = {},
  previewData = undefined,
  type = 'page',
  where = {},
}) {
  const filters = [];
  const wheres = Object.entries(where);

  if (wheres.length > 0) {
    wheres.forEach(([key, value]) =>
      filters.push(prismic.filter.at(key, value)),
    );
  }

  const client = createClient();
  const docs = options.pageSize
    ? await client.getByType(type, { ...options })
    : await client.getAllByType(type, { ...options });

  if (Array.isArray(docs)) {
    docs.forEach((doc) => {
      if (doc.data?.parent && doc.data?.parent?.url !== undefined) {
        doc.url = `${doc.data.parent.url}${doc.url}`;
      }
    });
  }

  return docs || null;
}

export async function getDocByUID({
  options = {},
  previewData,
  type = 'page',
  uid,
}) {
  const client = createClient();
  const doc = await client.getByUID(type, uid, { ...options });
  return doc || null;
}

export async function getSingle({ previewData, type, options = {} }) {
  const client = createClient();
  const doc = await client.getSingle(type, { ...options });
  return doc || null;
}

export const createPath = (currentPage, pages) => {
  let path = [];
  path.unshift(currentPage.uid);
  pushParent(currentPage);

  function pushParent(currentPage) {
    const parentUid = currentPage?.data?.parent?.uid;

    if (!parentUid) {
      return;
    }

    path.unshift(parentUid);
    const parent = pages.find(({ uid }) => uid === parentUid);
    pushParent(parent);
  }

  return `/${path.join('/')}`;
};
