"use client";

// Check if GTM is enabled via environment variable
export const IS_GTM_ENABLED =
  process.env.NEXT_PUBLIC_GTM !== undefined &&
  process.env.NEXT_PUBLIC_GTM !== "";

// Centralized tracking configuration
export const trackingConfig = {
  gtmId: process.env.NEXT_PUBLIC_GTM || "",
  cookieBannerCookieName: "cookieConsent",
};
